<template>
    <div v-if="isAllowedTo('SupplierCentral/editArtworkConfiguration')" class="fhf product-add">
        <mercur-stepper :isFull="true" :active-step.sync="activeStep" ref="stepper">
            <mercur-step id="ArtworkManagementStartStep" title="Start"></mercur-step>
            <mercur-step id="ArtworkManagementUniqueCombinations" title="Unique Combinations"></mercur-step>
            <mercur-step id="ArtworkManagementBasicArtworkConfig" title="Basic Artwork Config"></mercur-step>
            <mercur-step id="ArtworkManagementManualCheckConfig" title="Manual Check Config"></mercur-step>
            <mercur-step id="ArtworkManagementAutoCheckConfig" title="Auto Check Config"></mercur-step>
            <mercur-step id="ArtworkManagementConfirmation" title="Confirmation"></mercur-step>
        </mercur-stepper>

        <template v-if="configuration && !isGettingConfiguration && hasSettings">
            <router-view class="fhf" ref="routeview" v-bind="$data" @setStep="setActiveStep"></router-view>
        </template>
        <mercur-card class="mx-0 py-3 text-center" v-else-if="isGettingConfiguration || hasSettings">
            <mercur-spinner />
        </mercur-card>
        <mercur-card class="mx-0 py-3 text-center" v-else>
            There seems to be an issue fetching your data. Try refreshing the page.
        </mercur-card>
    </div>
    <p v-else class="permission-message">Not allowed to see this view</p>
</template>

<script>

import CONFIG from '@root/config'
import { mapGetters } from 'vuex'
import { getDeepProperty } from '@/components/utils/Utils'
export default {
    name: 'ArtworkManagementConfigurations',
    data () {
        return {
            configuration: null,
            isGettingConfiguration: false,
            artworkExportSettings: {},
            configurationSettings: null,
            configurationSettingsInitialValues: null,
        }
    },

    computed: {
        ...mapGetters('auth', ['user']),
        activeStep: {
            get () {
                return this.$route.name
            },
            set (value) {
                this.$router.push({
                    name: value,
                    params: {
                        ...this.$route.params,
                    },
                })
            },
        },
        breadcrumbElement () {
            return {
                name: getDeepProperty('configuration.productConfigurationName', this, '...'),
            }
        },
        hasSettings () {
            const routeName = this.$route.name
            if (routeName === 'ArtworkManagementStartStep') {
                return true
            }

            return !!this.configurationSettings
        },
    },

    watch: {
        activeStep () {
            this.getArtworkConfiguration()
            this.getArtworkConfigurationSettings()
        },
    },

    methods: {
        setActiveStep (route) {
            this.$refs.stepper.setActiveStep(route.name)
        },
        async getArtworkConfiguration (ignoreLoader = false) {
            if (!ignoreLoader) {
                this.isGettingConfiguration = true
            }
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.GET.replace('{productConfigurationId}', this.$route.params.productConfigurationId)
            await this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'configuration', data.data)
            }).finally(() => {
                this.isGettingConfiguration = false
            })
        },
        getArtworkConfigurationSettings () {
            const routeName = this.$route.name
            if (routeName === 'ArtworkManagementStartStep') {
                return
            }

            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_CONFIGURATION_SETTINGS.LIST
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'configurationSettings', data.data)
                this.$set(this, 'configurationSettingsInitialValues', JSON.parse(JSON.stringify(data.data)))
            }).finally(() => {
                this.isGettingConfiguration = false
            })
        },
    },
    created () {
        this.getArtworkConfiguration()
        this.getArtworkConfigurationSettings()
    },
}
</script>

<style lang="scss" scoped>
.product-add {
    padding: 7px 7px;
}

.stick-element {
    position: sticky;
    bottom: 0;
}

.checkmark-icon {
    float: right;
    font-size: 100px !important;
    margin-left: auto;
}

.product-conflicts {
    margin-top: 10px;
    padding-bottom: 0;

    &__description {
        padding-bottom: 0;
    }
}

.product-conflict {
    &__attributes {
        padding-left: 0;
    }

    &__attribute {
        text-indent: -35px;
        padding-left: 50px;
        list-style-position: inside;
        margin: 0 0 6px -10px;
        font-size: 12px;
        line-height: 1.4em;
    }
}
</style>
